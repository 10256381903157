import {
  Box,
  Text,
  Icon,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  VStack,
  FormControl,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { IconChevronDown, IconChevronLeft } from "@bit/feedloop.qore.icons";
import WebComponentProvider from "../WebComponentProvider";
import { css } from "@emotion/react";
import React from "react";
import register from "preact-custom-element";
import makeUser from "@feedloop/qore-sdk/lib/user";
import useSWR from "swr";
import { FocusableElement } from "@chakra-ui/utils";
import produce from "immer";

const QoreNavigation = (props: {
  projectid: string;
  orgid: string;
  teamid?: string;
  active: string;
  actions: React.ReactNode;
}) => {
  const initialFocusRef = React.useRef<FocusableElement>(null);
  const [state, setState] = React.useState({
    orgID: props.orgid,
    teamID: props.teamid,
  });
  const user = React.useMemo(() => {
    const user = makeUser();
    const token = localStorage.getItem("token");
    token && user.setToken(token.replace("Bearer ", ""));
    return user;
  }, []);

  const organizations = useSWR(
    [user],
    async (user: ReturnType<typeof makeUser>) => {
      const orgs = await user.organizations();
      return orgs;
    }
  );

  const projects = useSWR(
    [user, state.orgID],
    async (user: ReturnType<typeof makeUser>, orgID) => {
      if (!state.orgID) return [];
      const projects = await user.projects(orgID, {});
      return projects;
    }
  );
  const selectedOrg = React.useMemo(
    () => organizations.data?.find((org) => org.id === props.orgid),
    [props.orgid, organizations.data]
  );

  const selectedProject = React.useMemo(
    () => projects.data?.find((project) => project.id === props.projectid),
    [props.projectid, projects.data]
  );

  const teamPath = props.teamid ? `/teams/${props.teamid}` : "";

  const navigations = React.useMemo<Array<{ label: string; url: string }>>(
    () => [
      {
        label: "Data",
        url: `/orgs/${props.orgid}${teamPath}/projects/${props.projectid}/tables`,
      },
      {
        label: "Apps",
        url: `/apps/orgs/${props.orgid}${teamPath}/projects/${props.projectid}`,
      },
      {
        label: "Function",
        url: `/orgs/${props.orgid}${teamPath}/projects/${props.projectid}/functions`,
      },
      {
        label: "Setting",
        url: `/orgs/${props.orgid}${teamPath}/projects/${props.projectid}/settings`,
      },
      {
        label: "API",
        url: `/orgs/${props.orgid}${teamPath}/projects/${props.projectid}/api-docs`,
      },
    ],
    [props.orgid, props.projectid]
  );

  return (
    <WebComponentProvider>
      <Box
        w="full"
        height="64px"
        display="flex"
        borderBottom="1px solid #e6e9ec"
        alignItems="center"
      >
        <Flex width="292px" padding="0 16px" alignItems="center">
          <Box as="a" href="/" cursor="pointer">
            <Icon boxSize="24px" as={IconChevronLeft} />
          </Box>
          <Box flex="1" paddingLeft="16px">
            <Popover initialFocusRef={initialFocusRef}>
              <PopoverTrigger>
                <Flex alignItems="center" cursor="pointer">
                  <Box textAlign="left" flex="1">
                    <Text fontWeight="600" fontSize="14px">
                      {selectedProject?.name || "..."}
                    </Text>
                    <Text fontSize="12px" color="gray.400">
                      {selectedOrg?.name || "..."}
                    </Text>
                  </Box>
                  <Box>
                    <Icon boxSize="24px" as={IconChevronDown} />
                  </Box>
                </Flex>
              </PopoverTrigger>
              <PopoverContent>
                <Box padding="24px">
                  <VStack
                    spacing="24px"
                    width="full"
                    as="form"
                    // @ts-ignore
                    ref={initialFocusRef}
                    alignItems="flex-start"
                  >
                    <Box>
                      <Text fontWeight="bold">Select project</Text>
                    </Box>
                    <FormControl>
                      <FormLabel color="gray.400" fontSize="12px">
                        Organization
                      </FormLabel>
                      <Select
                        value={state.orgID}
                        placeholder="Select organization"
                        onChange={(e) => {
                          const val = e.currentTarget.value;
                          setState((state) =>
                            produce(state, (draft) => {
                              draft.orgID = val;
                            })
                          );
                        }}
                      >
                        {organizations.data?.map((org) => (
                          <option key={org.id} value={org.id}>
                            {org.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel color="gray.400" fontSize="12px">
                        Project
                      </FormLabel>
                      <Select
                        value={props.projectid}
                        placeholder="Select project"
                        onChange={(e) => {
                          const projectID = e.currentTarget.value;
                          window.location.href = `/orgs/${state.orgID}/projects/${projectID}/tables`;
                        }}
                      >
                        {projects.data?.map((project) => (
                          <option key={project.id} value={project.id}>
                            {project.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </VStack>
                </Box>
              </PopoverContent>
            </Popover>
          </Box>
        </Flex>
        <Box height="full" borderRight="1px solid #e6e9ec" />
        <Box
          css={css`
            padding: 0 4px;
          `}
        >
          {navigations.map((nav) => (
            <Text
              as="a"
              key={nav.label}
              href={nav.url}
              css={css`
                padding: 22px 16px;
                font-size: 14px;
                color: var(--chakra-colors-gray-600);

                ${props.active === nav.label &&
                css`
                  border-bottom: 2px solid #0065ff;
                  font-weight: 600;
                  color: #001e4d;
                `}
              `}
            >
              {nav.label}
            </Text>
          ))}
        </Box>
        <Box flex={1}></Box>
        <Box padding="0 16px">{props.actions}</Box>
      </Box>
    </WebComponentProvider>
  );
};

register(QoreNavigation, "qore-navigation", ["actions"], { shadow: true });
